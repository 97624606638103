import Glide from '@glidejs/glide';

document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector(".glide-endorsements")) {
    let glide = new Glide('.glide-endorsements', {
      type: 'carousel',
      perView: 1,
      focusAt: 'center'
    });
    glide.mount();
  }
});

